<template>
      <div class="container mt-5">
      <div class="row">

        <div class="col-lg-12" style="margin-bottom:25px;">
            <div class="card" style="padding:10px 15px;border-radius:50px;">
                <div style="float:right;width:100%;">
                    <div style="float:right;width:50%">
                        <div style="float:right;">
                            <button @click="downloadCSV" class="btn btn-primaryy mx-1">دانلود CSV</button>
                        </div>
                    </div>
                    <div style="float:left;width:30%">
                        <input type="text" v-model="filter.search" @input="applyFilters" placeholder="جستجوی نام، ایمیل" class="form-control form-search">
                    </div>
                </div>
            </div>
        </div>
        <h1>جدول خرید کاربران</h1>
       
        <div class="card col-lg-9 col-md-8 col-12" style="padding-top:15px;">
        

                <div class="table-responsive mt-3">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">ردیف</th>
                                <th scope="col">نام و نام خانوادگی</th>
                                <th scope="col">ایمیل</th>
                                <th scope="col">شماره همراه</th>
                                <th v-for="(webinar, index) in sortedWebinarTitles" :key="webinar.ID" scope="col" :style="{ backgroundColor: colors[index] }">
                                    {{ index + 1 }}
                                </th>
                                <th scope="col">
                                    <button @click="toggleSort('totalWebinars')" class="btn btn-link btn-sm">
                                        جمع وبینارها
                                        <svg v-if="sortOrder === 'asc' && sortKey === 'totalWebinars'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icon-tabler-arrow-down">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <line x1="12" y1="5" x2="12" y2="19"/>
                                            <line x1="18" y1="13" x2="12" y2="19"/>
                                            <line x1="6" y1="13" x2="12" y2="19"/>
                                        </svg>
                                        <svg v-else xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icon-tabler-arrow-up">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <line x1="12" y1="5" x2="12" y2="19"/>
                                            <line x1="18" y1="11" x2="12" y2="5"/>
                                            <line x1="6" y1="11" x2="12" y2="5"/>
                                        </svg>
                                    </button>
                                </th>
                                <th scope="col">
                                    <button @click="toggleSort('totalPurchases')" class="btn btn-link btn-sm">
                                        جمع خریدها
                                        <svg v-if="sortOrder === 'asc' && sortKey === 'totalPurchases'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icon-tabler-arrow-down">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <line x1="12" y1="5" x2="12" y2="19"/>
                                            <line x1="18" y1="13" x2="12" y2="19"/>
                                            <line x1="6" y1="13" x2="12" y2="19"/>
                                        </svg>
                                        <svg v-else xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icon-tabler-arrow-up">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <line x1="12" y1="5" x2="12" y2="19"/>
                                            <line x1="18" y1="11" x2="12" y2="5"/>
                                            <line x1="6" y1="11" x2="12" y2="5"/>
                                        </svg>
                                    </button>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(user, index) in filteredUsers" :key="user.email">
                                <td>{{ index + 1 }}</td>
                                <td>{{ user.firstNameLastName }}</td>
                                <td>{{ user.email }}</td>
                                <td>{{ user.mobileNumber }}</td>
                                <td v-for="(webinar, idx) in sortedWebinarTitles" :key="webinar.ID" :style="{ backgroundColor: colors[idx] }">
                                    <span v-if="hasBoughtWebinar(user.email, webinar.ID)">
                                        {{ formatNum(getAmountPaid(user.email, webinar.ID)) }} تومان
                                        ({{ getPaidRatio(user.email, webinar.ID) }})
                                    </span>
                                </td>
                                <td>{{ getTotalWebinars(user) }}</td>
                                <td>{{ formatNum(getTotalPurchases(user)) }} تومان</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
          
        </div>
            
            <div class="col-lg-3 col-md-4 col-12">
                    <!-- Add this table for statistics at the top of the table -->
                    
                <div class="card">
                    <div v-for="(webinar, index) in sortedWebinarTitles" :key="webinar.ID" class="legend-item" :style="{ backgroundColor: colors[index] }">
                        {{ index + 1 }}: {{ webinar.Title }}
                    </div>
                </div>
            </div>
            <div v-if="error" class="alert alert-danger mt-3">
                {{ error }}
            </div>
    </div></div>
</template>

<script>
import axios from 'axios';
export default {
    data() {
        return {
            webinars: {},
            error: '',
            webinarTitles: [],
            transactions: [],
            usersWithTransactions: [],
            sortKey: 'totalWebinars',
            sortOrder: 'desc',
            filter: {
                search: '',
            },
            colors: []
        };
    },
    computed: {
        sortedWebinarTitles() {
            const persianOrder = {
                'اول': 1,
                'دوم': 2,
                'سوم': 3,
                'چهارم': 4,
                'پنجم': 5,
                'ششم': 6,
                'هفتم': 7,
                'هشتم': 8,
                'نهم': 9
            };

            return [...this.webinarTitles].sort((a, b) => {
                const titleA = a.Title.split(' - بار')[0];
                const titleB = b.Title.split(' - بار')[0];
                
                const compareTitles = titleA.localeCompare(titleB);
                if (compareTitles !== 0) {
                    return compareTitles;
                }

                const suffixA = a.Title.split(' - بار')[1] || '';
                const suffixB = b.Title.split(' - بار')[1] || '';
                
                const ordinalA = persianOrder[suffixA.trim()] || 0;
                const ordinalB = persianOrder[suffixB.trim()] || 0;

                return ordinalA - ordinalB;
            });
        },
        filteredUsers() {
            const searchTerm = this.filter.search.toLowerCase();
            if (!searchTerm) {
              return this.sortedUsers; // Return all users if search term is empty
            }
            return this.sortedUsers.filter(user => {
              return (
                user.firstNameLastName.toLowerCase().includes(searchTerm) ||
                user.email.toLowerCase().includes(searchTerm)
                );
            });
        },
        sortedUsers() {
          if (!this.sortKey) {
            return this.usersWithTransactions;
          }

          const sorted = [...this.usersWithTransactions];
          sorted.sort((a, b) => {
             let valueA = null;
             let valueB = null;

             if (this.sortKey === 'totalWebinars') {
                valueA = this.getTotalWebinars(a);
                valueB = this.getTotalWebinars(b);
             }
             else if(this.sortKey === 'totalPurchases'){
                valueA = this.getTotalPurchases(a)
                valueB = this.getTotalPurchases(b)
             }
             if (valueA === null || valueB === null) {
                  return 0; // Return 0 if the key is not found
                }

              const comparison = (valueA > valueB) ? 1 : ((valueB > valueA) ? -1 : 0);
              return this.sortOrder === 'asc' ? comparison : -comparison;
          });

        return sorted;
      }
    },
    methods: {
        applyFilters() {
            //this.filter.search = this.filter.search.trim();
        },
        formatNum(number) {
          let intNumber = Math.floor(number);
          let formattedNumber = intNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          return formattedNumber;
        },
        toggleSort(key) {
            if (this.sortKey === key) {
                this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
            } else {
                this.sortKey = key;
                this.sortOrder = 'asc';
            }
        },
        fetchWebinars() {
            const token = localStorage.getItem('jwt');
            axios.post('https://dashboard.ordmenpodcast.com/v/webinars_list.php', {}, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(response => {
                if (response.data && response.data.data) {
                    response.data.data.forEach(webinar => {
                        this.webinars[webinar.ID] = { name: webinar.Title, hazine: webinar.Hazine };
                        this.webinarTitles.push({ ID: webinar.ID, Title: webinar.Title });
                    });
                    this.generateColors(this.webinarTitles.length);
                    this.fetchTransactions();
                } else {
                    this.error = response.data.error;
                    console.log(response.data);
                }
            }).catch(error => {
                this.error = 'پاسخی از سرور دریافت نشد. لطفا بعدا دوباره تلاش کنید.';
                console.error('Error fetching webinars:', error);
            });
        },
        generateColors(count) {
    const colors = [];
    const seed = 43; // Fixed seed for consistency
    const random = this.seededRandom(seed); // Initialize seeded random generator

    for (let i = 0; i < count; i++) {
        const hue = Math.floor(random() * 360); // Random hue
        const saturation = 50 + Math.floor(random() * 30); // 50-80% saturation
        const lightness = 80 + Math.floor(random() * 10); // 80-90% lightness (light colors)
        colors.push(`hsl(${hue}, ${saturation}%, ${lightness}%)`);
    }
    this.colors = colors;
},

seededRandom(seed) {
    // A simple seeded random number generator
    let x = Math.sin(seed) * 10000;
    return () => {
        x = Math.sin(x) * 10000;
        return x - Math.floor(x); // Returns a value between 0 and 1
    };
},
        fetchTransactions() {
            const token = localStorage.getItem('jwt');
            axios.post('https://dashboard.ordmenpodcast.com/v/list_transactions_users.php', {}, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(response => {
                if (response.data && response.data.length > 0) {
                    this.transactions = response.data;
                    this.groupTransactionsByEmail();
                } else {
                    this.error = 'هیچ تراکنشی یافت نشد.';
                }
            }).catch(error => {
                this.error = 'پاسخی از سرور دریافت نشد. لطفا بعدا دوباره تلاش کنید.';
                console.error('Error fetching transactions:', error);
            });
        },
        groupTransactionsByEmail() {
            const usersWithTransactions = [];
            const transactionsByEmail = {};

            this.transactions.forEach(transaction => {
                if (!transactionsByEmail[transaction.EMailAddress]) {
                    transactionsByEmail[transaction.EMailAddress] = {
                        productIds: new Set(),
                        firstNameLastName: transaction.FirstNameLastName,
                        mobileNumber: transaction.MobileNumber,
                         amountsPaid: {}
                    };
                }
                const user = transactionsByEmail[transaction.EMailAddress];
                 user.productIds.add(transaction.product_id);
                 user.amountsPaid[transaction.product_id] = transaction.amount;
            });

            for (const email in transactionsByEmail) {
                const transactionData = transactionsByEmail[email];
                usersWithTransactions.push({
                    email: email,
                    productIds: Array.from(transactionData.productIds),
                    firstNameLastName: transactionData.firstNameLastName,
                    mobileNumber: transactionData.mobileNumber,
                    amountsPaid: transactionData.amountsPaid
                });
            }
             this.usersWithTransactions = usersWithTransactions;
        },
        hasBoughtWebinar(email, webinarId) {
            const user = this.usersWithTransactions.find(user => user.email === email);
            return user && user.productIds.includes(webinarId);
        },
        getTotalPurchases(user) {
            let total = 0;
            for (const webinarId of user.productIds) {
                total += parseInt(user.amountsPaid[webinarId], 10) || 0;
            }
            return total;
        },
        getAmountPaid(email, webinarId) {
            const user = this.usersWithTransactions.find(user => user.email === email);
            if (user && user.amountsPaid[webinarId]) {
                return user.amountsPaid[webinarId];
            }
            return 0;
        },
        getPaidRatio(email, webinarId) {
            const user = this.usersWithTransactions.find(user => user.email === email);
            if (user && user.amountsPaid[webinarId] && this.webinars[webinarId]) {
                const paidAmount = user.amountsPaid[webinarId];
                const hazine = this.webinars[webinarId].hazine;
                const ratio = paidAmount / hazine;

                if (ratio === 1) {
                    return 100 + '%';
                }

                if (!isFinite(ratio)) {
                    return 100 + '%';
                }
                return ratio.toFixed(2)*100 + '%';
            }
            return 0;
        },
        getTotalWebinars(user) {
            return user.productIds.length;
        },
        downloadCSV() {
            const BOM = "\uFEFF";
            const csvContent = BOM + this.convertToCSV(this.filteredUsers);
            this.downloadFile(csvContent, 'users_webinars.csv', 'text/csv;charset=utf-8');
        },
        convertToCSV(data) {
            const header = [
                "ردیف",
                "نام و نام خانوادگی",
                "ایمیل",
                "شماره همراه",
                ...this.sortedWebinarTitles.map(webinar => webinar.Title),
                "جمع وبینارها",
                "جمع خریدها",
            ].join(',');

            const rows = data.map((user, index) => {
                const webinarData = this.sortedWebinarTitles.map(webinar => {
                    if (this.hasBoughtWebinar(user.email, webinar.ID)) {
                        return `${this.getAmountPaid(user.email, webinar.ID)} تومان (${this.getPaidRatio(user.email, webinar.ID)})`;
                    }
                    return '';
                });
                return [
                    index + 1,
                    user.firstNameLastName,
                    user.email,
                    user.mobileNumber,
                    ...webinarData,
                    this.getTotalWebinars(user),
                    this.getTotalPurchases(user)
                ].join(',');
            });
            return [header, ...rows].join('\n');
        },
        downloadFile(content, filename, contentType) {
            const blob = new Blob([content], { type: contentType });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = filename;
            a.click();
            URL.revokeObjectURL(url);
        },
    },
    mounted() {
        this.fetchWebinars();
        this.sortKey = 'totalWebinars';
        this.sortOrder = 'desc';
    }
};
</script>

<style scoped>
.container {
    width: 100% !important;
    max-width: 100% !important;
    font-size: 12.5px !important;
}

.legend-item {
    padding: 10px;
    font-size: 12px;
    word-break: break-word;
}
</style>